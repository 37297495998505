var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "18px" },
          attrs: { type: "primary", size: "small" },
          on: { click: _vm.changeCompare },
        },
        [_vm._v("一键切换比较")]
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.value || [], "row-key": "keys", border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "fieldName", label: "字段名称", width: "180" },
          }),
          _c("el-table-column", { attrs: { prop: "type", label: "字段类型" } }),
          _c("el-table-column", {
            attrs: { prop: "hostType", label: "字段分类" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.getDictCode(
                            scope.row.hostType,
                            "crmlog_host_type"
                          )
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "desc", width: "200", label: "字段描述" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input", {
                      staticClass: "cell-input",
                      model: {
                        value: scope.row.desc,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "desc", $$v)
                        },
                        expression: "scope.row.desc",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "onlyKey",
              width: "200",
              label: "唯一标识(one2Many专用)",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input", {
                      staticClass: "cell-input",
                      model: {
                        value: scope.row.onlyKey,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "onlyKey", $$v)
                        },
                        expression: "scope.row.onlyKey",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "onlyKey",
              width: "200",
              "show-overflow-tooltip": "",
              label: "唯一描述语句（one2Many专属）",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input", {
                      staticClass: "cell-input",
                      model: {
                        value: scope.row.onlyKeyName,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "onlyKeyName", $$v)
                        },
                        expression: "scope.row.onlyKeyName",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "compareFalg", label: "是否比较" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#13ce66",
                        "inactive-color": "#ff4949",
                        "active-value": 1,
                        "inactive-value": 0,
                      },
                      model: {
                        value: scope.row.compareFalg,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "compareFalg", $$v)
                        },
                        expression: "scope.row.compareFalg",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.hostType !== 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.editField(scope)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "Modal",
        {
          ref: "modalForm",
          attrs: { modalConfig: _vm.modalConfig },
          on: { onClose: _vm.closeModal, onOk: _vm.okModal },
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  return _vm.editField(_vm.scopeData, true)
                },
              },
            },
            [_vm._v("刷新")]
          ),
          _c("FieldMap", { attrs: { value: _vm.childrenList } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }