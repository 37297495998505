<!--
 * @Autor: Nehc
 * @Date: 2020-12-11 14:53:11
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-16 11:13:19
-->
<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import FieldMap from './field_map';

formCreate.component('FieldMap', FieldMap);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      // form内组件
      rule: [],

    };
  },

  async mounted() {
    this.rule = await this.getFormRule('dms_dialog_add');
    this.rule.map((v) => {
      const rowData = v;
      if (rowData.field === 'moduleCode') {
        rowData.options = [
          {
            value: 'mdm',
            label: 'mdm',
          },
          {
            value: 'dms',
            label: 'dms',
          },
          {
            value: 'tpm',
            label: 'tpm',
          },
          {
            value: 'sfa',
            label: 'sfa',
          },
        ];
      }
      if (rowData.field === 'btnName') {
        rowData.props = {
          ...rowData.props,
          disabled: false,
          name: '查询',
        };
        rowData.on = {
          click: this.searchBtn,
        };
      }
      return rowData;
    });
    // this.rule.push({
    //   type: 'FieldMap',
    //   field: 'list',
    //   title: '',
    //   props: {
    //     functionCode: '',
    //     selection: [],
    //     idKey: 'id',
    //     type: '',
    //     onlyKey: '',
    //     notView: this.formConfig.edit !== true,
    //     formData: this.formConfig,
    //   },
    //   value: [],
    // });
    this.reload(this.rule);
    if (this.formConfig.selection[0]) {
      this.getData();
    }
    // // 跨组件传type和onlyKey
    // this.getRule('type').on.change = this.typeChange;
    // this.getRule('onlyKey').on.change = this.onlyKeyChange;
  },

  methods: {
    searchBtn() {
      if (this.getFormData()) {
        const params = {
          ...this.getFormData(),
        };
        let requestUrl = '/m/log/findFieldsByType';
        requestUrl = `/${this.getFieldValue('moduleCode')}${requestUrl}`;
        request.post(requestUrl, params).then((res) => {
          if (res.code === 200) {
            const { result } = res;
            this.getRule('fieldList').props = {
              formData: {
                ...this.getFormData(),
              },
            };
            this.setValue({
              fieldList: result,
            });
          } else {
            this.$message.error(res.messge);
          }
        });
      }
    },
    // 编辑赋值
    getData() {
      request.get(`/mdm/m/log/findById/${this.formConfig.selection[0]}`).then((res) => {
        if (res.code === 200) {
          this.setValue(res.result);
          const formData = res.result;
          delete formData.fieldList;
          delete formData.id;
          this.getRule('fieldList').props = {
            formData: {
              ...formData,
            },
          };
        } else {
          this.$message.error(res.messge);
        }
      });
      // console.log(this.formConfig);
      // const data = {
      //   ...this.formConfig,
      //   code: this.formConfig.code,
      //   type: this.formConfig.type,
      //   onlyKey: this.formConfig.onlyKey,
      //   businessName: this.formConfig.businessName,
      // };
      // this.setValue(data);
    },
    // 提交事件
    async submit() {
      let requestUrl = '';
      if (this.formConfig.selection[0]) {
        requestUrl = '/mdm/m/log/update';
      } else {
        requestUrl = '/mdm/m/log/add';
      }
      const params = {
        ...this.getFormData(),
        id: this.formConfig.selection[0] || '',
      };
      delete params.btnName;
      console.log(params);
      request.post(requestUrl, params).then((res) => {
        if (res.code === 200) {
          this.$emit('onClose');
          this.$emit('onGetList');
        } else {
          this.$message.error(res.messge);
        }
      });
    },
  },
};
</script>
