<!--
 * @Autor: Nehc
 * @Date: 2020-12-11 10:54:51
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-16 11:13:34
-->
<script>
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import Form from '../form';

export default {
  extends: TablePage,
  components: {
    Modal,
    Form,
  },
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  async created() {
    const columns = await this.getConfigList('dms_dialog');
    columns.map((v) => {
      const rowData = v;
      if (rowData.field === 'createTime') {
        rowData.formatter = ({ row }) => `${row.createDate} ${row.createDateSecond}`;
      }
      return rowData;
    });
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'edit') {
        this.modalConfig.title = '编辑';
        this.formConfig.selection = [row.id];
        this.openFull();
      } else if (val.code === 'view') {
        this.modalConfig.title = '查看';
        this.formConfig.selection = [row.id];
        this.openFull();
      } else if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.formConfig.selection = [];
        this.openFull();
      }
      return true;
    },
  },
};
</script>
