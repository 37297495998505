<template>
  <div class="home">
    <LogRule />
  </div>
</template>

<script>
import LogRule from './table';

export default {
  name: 'log',
  components: {
    LogRule,
  },
};
</script>
