<!--
 * @Autor: Nehc
 * @Date: 2020-12-11 15:12:46
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-18 14:35:07
-->
<template>
  <div>
    <!-- <el-button type="primary" @click="getTable" size="small" v-if="notView">查询</el-button> -->
    <!-- <el-button type="primary" @click="editTable" size="small" v-if="!notView">编辑查询</el-button> -->
    <el-button style="margin-bottom: 18px;" type="primary" @click="changeCompare" size="small">一键切换比较</el-button>
    <el-table
      :data="value || []"
      style="width: 100%"
      row-key="keys"
      border
    >
      <el-table-column prop="fieldName" label="字段名称" width="180">
      </el-table-column>
      <el-table-column prop="type" label="字段类型">
      </el-table-column>
      <el-table-column prop="hostType" label="字段分类">
        <template slot-scope="scope">
          {{getDictCode(scope.row.hostType, 'crmlog_host_type')}}
        </template>
      </el-table-column>
      <el-table-column prop="desc" width="200" label="字段描述">
        <template slot-scope="scope">
          <el-input v-model="scope.row.desc" class="cell-input" />
        </template>
      </el-table-column>
      <el-table-column prop="onlyKey" width="200" label="唯一标识(one2Many专用)">
        <template slot-scope="scope">
          <el-input v-model="scope.row.onlyKey" class="cell-input" />
        </template>
      </el-table-column>
      <el-table-column prop="onlyKey" width="200" show-overflow-tooltip label="唯一描述语句（one2Many专属）">
        <template slot-scope="scope">
          <el-input v-model="scope.row.onlyKeyName" class="cell-input" />
        </template>
      </el-table-column>
      <el-table-column prop="compareFalg" label="是否比较">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.compareFalg"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value='1'
            :inactive-value='0'
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope" >
          <el-button type="text" v-if="scope.row.hostType !== 0" @click="editField(scope)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 弹框 -->
    <Modal :modalConfig="modalConfig"
           ref="modalForm"
           @onClose="closeModal"
           @onOk="okModal">
        <el-button type="text" @click="editField(scopeData,true)">刷新</el-button>

        <FieldMap :value="childrenList"/>
    </Modal>
  </div>
</template>

<script>
import request from '../../../../../../utils/request';
import Modal from '../../../../../../components/modal/components/full.vue';

export default {
  name: 'FieldMap',
  components: {
    Modal,
  },
  props: {
    type: String,
    onlyKey: String,
    value: Array,
    notView: Boolean,
    formData: Object,
  },
  data() {
    return {
      modalConfig: {
        title: '选择字段',
        visible: false,
        showFooter: true,
      },
      dictCodeList: null,
      fieldTable: [],
      childrenList: [],
      inType: '',
      inKey: '',
      rowForm: {},
      isShow: '',
      scopeData: null,
    };
  },
  created() {
    const list = ['crmlog_host_type'];
    this.setDictCode(list);
  },
  methods: {
    // 切换是否比较
    changeCompare() {
      this.value.forEach((item) => {
        if (item.compareFalg === 1) {
          item.compareFalg = 0;
        } else if (item.compareFalg === 0) {
          item.compareFalg = 1;
        }
      });
    },
    // 获取列表中包含的数据字典
    setDictCode(list) {
      request.post('/mdm/mdmdictdata/batchDictSelect', list).then((res) => {
        this.dictCodeList = res.result;
      });
    },
    // 获取列表中包含的数据字典
    getDictCode(val, code) {
      if (this.dictCodeList) {
        if (this.dictCodeList[code]) {
          const dict = this.dictCodeList[code].find((v) => v.dictCode === `${val}`);
          return dict ? dict.dictValue : val;
        }
        return val;
      }
      return val;
    },
    editField(scope, reset) {
      this.scopeData = scope;
      this.scopeIndex = scope.$index;
      if (!scope.row.childrenList || scope.row.childrenList.length === 0 || reset) {
        const { childrenList } = scope.row;
        const params = {
          ...this.formData,
          typeClassPath: scope.row.type,
        };
        let requestUrl = '/m/log/findFieldsByType';
        requestUrl = `/${this.formData.moduleCode}${requestUrl}`;
        request.post(requestUrl, params).then((res) => {
          if (res.code === 200) {
            const { result } = res;
            this.childrenList = JSON.parse(JSON.stringify(result));
            this.openFull();
          } else {
            this.$message.error(res.messge);
          }
        });
      } else {
        this.childrenList = JSON.parse(JSON.stringify(scope.row.childrenList));
        this.openFull();
      }
    },
    // 打开抽屉
    openFull() {
      this.modalConfig = {
        ...this.modalConfig,
        ...{ visible: true, type: 'Full' },
      };
    },
    okModal() {
      this.value[this.scopeIndex].childrenList = this.childrenList;
      this.modalConfig = { ...this.modalConfig, visible: false };
    },
    // 关闭弹窗
    closeModal() {
      this.modalConfig = { ...this.modalConfig, visible: false };
    },
  },
};
</script>

<style lang="less" scoped>
// .cell-input{
//   border: unset;
// }
</style>
